<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'所有故障'" :line="true" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
            ></CommonHeaders>
        </div>
        <div class="commonControl-body wrapper-list">
            <el-tabs
                :tab-position="'left'"
                v-model="sortIndex"
                style="height: auto"
                @tab-click="handleTabClick"
            >
                <el-tab-pane
                    v-for="item in sortIndexData"
                    :key="item.id"
                    :label="item.type_name"
                    :name="`${item.id}`"
                >
                    <!-- 分类内容 -->
                    <div
                        class="content"
                        style="
                            margin-left: 60px;
                            margin-top: 50px;
                            margin-bottom: 20px;
                            display: flow-root;
                        "
                    >
                        <el-col
                            :span="18"
                            v-for="data in tableData"
                            :key="data.id"
                            style="margin-bottom: 30px"
                            @click.native="toInfo(data)"
                        >
                            <el-card
                                :body-style="{
                                    padding: '30px 30px 0px 30px',
                                    cursor: 'pointer'
                                }"
                                shadow="hover"
                            >
                                <div style="margin-bottom: 20px">
                                    <div
                                        style="
                                            font-size: 18px;
                                            font-weight: 600;
                                        "
                                    >
                                        {{ data.abnormal_question }}
                                    </div>
                                </div>

                                <div style="margin-bottom: 20px">
                                    <div
                                        style="
                                            font-size: 14px;
                                            font-weight: 600;
                                        "
                                    >
                                        <div v-if="data.update">
                                            更新者:
                                            {{ data.update_account }}
                                            更新内容:
                                            {{ data.update_illustrate }}
                                        </div>
                                    </div>
                                </div>

                                <el-form
                                    ref="form"
                                    :inline="true"
                                    label-position="left"
                                >
                                    <el-form-item
                                        style="margin-right: 30px"
                                        label="更新类型:"
                                    >
                                        <span>{{ data.update_type }}</span>
                                    </el-form-item>

                                    <el-form-item
                                        style="margin-right: 30px"
                                        label="所属类目:"
                                    >
                                        <span>{{
                                            data.question_type.type_name
                                                ? data.question_type.type_name
                                                : '无关联'
                                        }}</span>
                                    </el-form-item>

                                    <el-form-item
                                        style="margin-right: 30px"
                                        label="软硬类型:"
                                    >
                                        <span>{{ data.types }}</span>
                                    </el-form-item>

                                    <el-form-item
                                        style="margin-right: 30px"
                                        label="提交人:"
                                        v-if="data.author"
                                    >
                                        <span>{{ data.author }}</span>
                                    </el-form-item>

                                    <el-form-item
                                        style="margin-right: 30px"
                                        label="内容更新次数:"
                                    >
                                        <span>{{ data.update_count }}</span>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </el-col>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {
    equipmentFaultManageQuestionTypeCreate,
    equipmentFaultManageQuestionAllIndex,
    equipmentFaultManageQuestionIndexList
} from '@/api/manage/operation/equipment_fault_manage.js';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: 'start',
    data() {
        return {
            sortIndex: '1',
            query: {
                //分页数据
                querypage: 1, // 获取页码
                pagesize: 10, // 每页返回数量
                keyword: '', // 搜索
                total: 0
            },
            tableData: [],
            sortIndexData: [], // 分类索引数据
            formConfig: [],
            formModel: {}
        };
    },
    components: {
        CommonHeaders
    },
    created() {
        this.getFirstLevel();
    },
    methods: {
        createFirstLevel() {
            equipmentFaultManageQuestionTypeCreate(this.formAdd).then(res => {
                if (res.code == 200) {
                    this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        getFirstLevel() {
            equipmentFaultManageQuestionAllIndex(this.query).then(res => {
                this.sortIndexData = res.data;
                console.log(this.sortIndexData, '获取接口分类索引数据');
            });
            this.handleTabClick();
        },
        handleTabClick() {
            equipmentFaultManageQuestionIndexList({
                id: this.sortIndex
            }).then(res => {
                this.tableData = res.data;
                console.log(this.tableData, '故障信息');
            });
        },
        toInfo(data) {
            console.log(data);
            this.$router.push({
                name: 'equipmentFaultManage_info',
                query: {
                    id: data.id
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper-list {
    width: 100vw;
    height: 100vh;
}
/deep/ .el-tabs__header {
    margin-top: 30px;
    text-align: center;
}
/deep/ .el-tabs__item {
    width: 220px;
    height: 60px;
    text-align: center !important;
    line-height: 60px;
}
</style>
